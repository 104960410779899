// Generated by Framer (69c895f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I910:1356;1187:16761"];

const variantClassNames = {"I910:1356;1187:16761": "framer-v-112iam3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, jjNPV8C_u: title ?? props.jjNPV8C_u ?? "Learn more about Liz"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jjNPV8C_u, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I910:1356;1187:16761", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vrrb9", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-112iam3", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I910:1356;1187:16761"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYWRmb3JkIExMIFJlZ3VsYXI=", "--framer-font-family": "\"Bradford LL Regular\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(26, 26, 26))"}}>Learn more about Liz</motion.p></React.Fragment>} className={"framer-u0ql9f"} data-framer-name={"CTA"} fonts={["CUSTOM;Bradford LL Regular"]} layoutDependency={layoutDependency} layoutId={"I910:1356;1187:16761;181:21699"} style={{"--extracted-r6o4lv": "rgb(26, 26, 26)", "--framer-paragraph-spacing": "0px"}} text={jjNPV8C_u} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vrrb9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vrrb9 .framer-zlpanl { display: block; }", ".framer-vrrb9 .framer-112iam3 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 1px; position: relative; width: min-content; }", ".framer-vrrb9 .framer-u0ql9f { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vrrb9 .framer-112iam3 { gap: 0px; } .framer-vrrb9 .framer-112iam3 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-vrrb9 .framer-112iam3 > :first-child { margin-top: 0px; } .framer-vrrb9 .framer-112iam3 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 131
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jjNPV8C_u":"title"}
 * @framerImmutableVariables false
 */
const FramerLSNWMwuQQ: React.ComponentType<Props> = withCSS(Component, css, "framer-vrrb9") as typeof Component;
export default FramerLSNWMwuQQ;

FramerLSNWMwuQQ.displayName = "Button Learn More";

FramerLSNWMwuQQ.defaultProps = {height: 21, width: 131};

addPropertyControls(FramerLSNWMwuQQ, {jjNPV8C_u: {defaultValue: "Learn more about Liz", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerLSNWMwuQQ, [{family: "Bradford LL Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/LSNWMwuQQ:default", url: "https://framerusercontent.com/assets/czdTX1ZK5nPGK7oe3s0GbaWXMjA.otf"}, url: "https://framerusercontent.com/assets/czdTX1ZK5nPGK7oe3s0GbaWXMjA.otf"}])